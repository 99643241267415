<template>
  <div class="header">
    <div class="container flex flex-ac">
      <div class="logo"><img src="../assets/images/logo.png" alt=""><span>LOGO</span></div>
      <div class="flex_bd  head-body">
        <el-menu :default-active="this.activeIndex" class="el-menu-nav" mode="horizontal">
          <el-menu-item index="1" @click="()=>{
              this.$router.push({path:'/',name:'Home',params:{uid:this.uid}});}"><b>{{ $t('nav.nav1') }}</b>
          </el-menu-item>
          <el-menu-item index="2" @click="()=>{
              this.$router.push({path:'/mining',name:'mining',params:{uid:this.uid}});}"
          ><b>{{ $t('nav.nav2') }}</b></el-menu-item>
          <el-menu-item index="3" @click="()=>{
              this.$router.push({path:'/extension',name:'extension',params:{uid:this.uid}});
            }"><b>{{ $t('nav.nav3') }}</b></el-menu-item>
          <el-menu-item index="4" @click="()=>{
              this.$router.push({path:'/income',name:'income',params:{uid:this.uid}});
            }"><b>{{ $t('nav.nav4') }}</b></el-menu-item>
        </el-menu>
      </div>
      <div class="menu-nav flex flex-ac ">
        <div class="hidden-xs">
          <el-button type="primary" plain @click="clickConnect(true)" :key="connectWalletKey">{{ connectWallet }}</el-button>
        </div>
        <div class="hidden-lg">
          <el-button type="primary" plain @click="clickConnect(false)" :key="connectWalletKey">{{ connectWallet }}</el-button>
        </div>
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="lan-menu">
            <i class="font_family icon-yuyan"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="beforeHandleCommand('English')">English</el-dropdown-item>
            <el-dropdown-item :command="beforeHandleCommand('Japanese')">日本語</el-dropdown-item>
            <el-dropdown-item :command="beforeHandleCommand('Korean')">한국어 공부 해요.</el-dropdown-item>
            <el-dropdown-item :command="beforeHandleCommand('chinese')">简体中文</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog
        :visible.sync="dialogVisible"
        :modal="false"
        top="30vh"
        width="70%"
        :before-close="handleClose">
      <div @click="openUrl('imtokenv2://navigate/DappView?url=https%3A%2F%2Fapi9981hubo.com')">
        <div  style="border: 0.1rem solid rgb(245, 245, 245); width: 80%; margin-left: 10%; height: 3rem; margin-top: 1rem; border-radius: 0.5rem; background-color: rgb(243, 243, 243);"><div  style="width: 2.5rem; height: 2.5rem; margin-top: 0.25rem; margin-left: 0.25rem; float: left;"><img  src="../assets/images/imtoken.97de1461.svg" style="width: 2.5rem; height: 2.5rem;"></div><div  style="width: 50%; height: 3rem; float: left; text-align: left; line-height: 3rem; margin-right: 0.5rem; color: rgb(41, 98, 239); margin-left: 1rem;"> imToken </div></div>
      </div>

      <div @click="openUrl('tpdapp://open?params={%22url%22:%22https%3A%2F%2Fapi9981hubo.com%2Fmining%2Fdefi%22,%22chain%22:%22TRON%22,%22source%22:%22%20%22}')">
        <div  style="border: 0.1rem solid rgb(245, 245, 245); width: 80%; margin-left: 10%; height: 3rem; margin-top: 1rem; border-radius: 0.5rem; background-color: rgb(243, 243, 243);"><div  style="width: 2.5rem; height: 2.5rem; margin-top: 0.25rem; margin-left: 0.25rem; float: left;"><img  src="../assets/images/tokenpocket.88df6c47.png" style="width: 2.5rem; height: 2.5rem;"></div><div  style="width: 50%; height: 3rem; float: left; text-align: left; line-height: 3rem; margin-right: 0.5rem; color: rgb(41, 98, 239); margin-left: 1rem;"> TokenPocket </div></div>
      </div>
      <!--       <div @click="openUrl('trust://open_url?coin_id=60&url=https://api9981hubo.com')">-->
      <!--        <div  style="border: 0.1rem solid rgb(245, 245, 245); width: 80%; margin-left: 10%; height: 3rem; margin-top: 1rem; border-radius: 0.5rem; background-color: rgb(243, 243, 243);"><div  style="width: 2.5rem; height: 2.5rem; margin-top: 0.25rem; margin-left: 0.25rem; float: left;"><img  src="../assets/images/tokenpocket.88df6c47.png" style="width: 2.5rem; height: 2.5rem;"></div><div  style="width: 50%; height: 3rem; float: left; text-align: left; line-height: 3rem; margin-right: 0.5rem; color: rgb(41, 98, 239); margin-left: 1rem;"> imToken </div></div>-->
      <!--      </div>-->
      <div style="width: 90%; margin-left: 5%; color: rgb(72, 71, 72); font-size: 0.9rem; margin-top: 1rem; text-align: center; line-height: 1.4rem;"> Please open it in <strong >mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like <a  href="https://www.tronlink.org" target="_blank" style="font-size: 0.9rem; color: rgb(41, 98, 239); font-weight: bold;">TronLink</a>, <a  href="https://www.tokenpocket.pro/" target="_blank" style="font-size: 0.9rem; color: rgb(41, 98, 239); font-weight: bold;">TokenPocket</a>, <a  href="https://token.im/" target="_blank" style="font-size: 0.9rem; color: rgb(41, 98, 239); font-weight: bold;"> imToken </a> etc. </div>
      <br/>
      <button class="copy-text  el-button  cobyDomObj el-button--default " data-clipboard-action="copy" data-clipboard-text="https://api9981hubo.com/#/" style="display: block; margin: auto; border-radius: 5rem; color: rgb(41, 98, 239);"
              @click="copyLink">
        <span><strong >Copy Link</strong></span>
      </button>

    </el-dialog>
  </div>
</template>

<script>
import {connect} from "@/api/api";

import NodeWalletConnect from "@walletconnect/node";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";

export default {

  data() {
    return {
      activeIndex: '1',
      navinput: '',
      drawer: false,
      direction: 'ltr',
      address: "xxxxxxxxxxxxxx",
      channelCode: "12sseja",
      walletType: "1",
      balance: "",
      connectWallet: this.$t('nav.connectWallet'),
      connectWalletKey: 1,
      walletAddress: '',
      uid: undefined,
      dialogVisible: false
    };
  },
  methods: {
    copyLink() {
      let _this = this;
      let clipboard = new this.clipboard(".copy-text");
      clipboard.on('success', function () {
        _this.$toast(_this.$t('invite.copySuccess'))
        _this.dialogVisible = false;
      });
      clipboard.on('error', function () {
        _this.$toast(_this.$t('invite.copyError'))
        _this.dialogVisible = false;
      });
    },
    openUrl(url){
      window.location.href= url
    },
    handleClose(done) {
      done();
    },
    login() {
      // Create connector
      const walletConnector = new NodeWalletConnect(
          {
            bridge: "https://bridge.walletconnect.org", // Required
          },
          {
            clientMeta: {
              description: "WalletConnect NodeJS Client",
              url: "https://nodejs.org/en/",
              icons: ["https://nodejs.org/static/images/logo.svg"],
              name: "WalletConnect",
            },
          }
      );
      // Check if connection is already established
      if (!walletConnector.connected) {
        // create new session
        walletConnector.createSession().then(() => {
          // get uri for QR Code modal
          const uri = walletConnector.uri;
          // display QR Code modal
          WalletConnectQRCodeModal.open(
              uri,
              () => {
                console.log("QR Code Modal closed");
              },
              true // isNode = true
          );
        });
      }

      // Subscribe to connection events
      walletConnector.on("connect", (error, payload) => {
        if (error) {
          throw error;
        }
        // Close QR Code Modal
        WalletConnectQRCodeModal.close(true);
        // Get provided accounts and chainId
        const {accounts, chainId} = payload.params[0];
        console.log("----------", accounts, chainId)
      });

      walletConnector.on("session_update", (error, payload) => {
        if (error) {
          throw error;
        }
        // Get updated accounts and chainId
        const {accounts, chainId} = payload.params[0];
        console.log("----------", accounts, chainId)
      });

      walletConnector.on("disconnect", (error, payload) => {
        if (error) {
          throw error;
        }
        console.log("payload:", payload)
        // Delete walletConnector
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleCommand(command) {
      switch (command.command) {
        case "chinese":
          this.$i18n.locale = "chinese";
          break;
        case "English":
          this.$i18n.locale = "English";
          break;
        case "Japanese":
          this.$i18n.locale = "Japanese";
          break;
        case "Korean":
          this.$i18n.locale = "Korean";
          break;
      }
      this.$root.$emit("i18n", "1")
      if (this.walletAddress === '') {
        this.connectWallet = this.$t('nav.connectWallet');
        this.connectWalletKey = this.connectWalletKey + 1;
      }
    },
    beforeHandleCommand(command) {
      return {
        command: command
      };
    },
    clickConnect: function (small) {
      if (small) {
        if(window.tronWeb === undefined){
          this.dialogVisible = true;
          return
        }
      }else {
        if(window.tronWeb === undefined){
          this.$toast(this.$t('invite.openLink'))
          return
        }
        if( window.tronWeb.defaultAddress.base58 === false){
          this.$toast(this.$t('invite.openLink1'))
          return
        }
      }
      // this.login();
      const that = this;
      if (!that.tronWeb) {
        that.tronWeb = window.tronWeb;
        if(!that.tronWeb){
          return;
        }
        if( that.tronWeb.defaultAddress == null){
          return;
        }
        that.walletAddress = that.tronWeb.defaultAddress.base58;
        console.log(that.walletAddress);
        if (that.walletAddress!== false) {
          // 截取中间6位
          that.connectWallet = that.walletAddress.substring(0, 6) + "...." + that.walletAddress.substring(that.walletAddress.length - 6, that.walletAddress.length)
          this.getBalance();
          connect({
            address: that.walletAddress,
            channelCode: localStorage.getItem('channelCode'),
            url: localStorage.getItem('url'),
            walletType: 1,
            balance: 0
          }).then(res => {
            localStorage.setItem('uid', res.data.uid)
            this.$root.$emit("uid", res.data.uid)
            this.uid = res.data.uid;
            localStorage.setItem('channelCode', res.data.channelCode)
            localStorage.setItem('address', res.data.address)
          })
        }
      }
    },
    async getBalance() {
      return await this.tronWeb.trx.getBalance(this.walletAddress).then(r => {
        return r;
      });
    }
  },created() {
    if(localStorage.getItem('address') == null || localStorage.getItem('address') === undefined){
      return
    }
    if(localStorage.getItem('address') !== 'false'){
      this.connectWallet= localStorage.getItem('address').substring(0, 6) + "...." +localStorage.getItem('address').substring(localStorage.getItem('address').length - 6, localStorage.getItem('address').length);
      connect({
        address: localStorage.getItem('address'),
        channelCode: this.channelCode,
        walletType: 1,
        balance: 0
      }).then(res => {
        localStorage.setItem('uid', res.data.uid)
        this.$root.$emit("uid", res.data.uid)
        this.uid = res.data.uid;
        localStorage.setItem('channelCode', res.data.channelCode)
      })
    }
  }
}
</script>
