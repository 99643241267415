<template>
  <div class="page-container">
    <div class="index-banner">
      <div class="container">
        <div class="index-bk">
          <div class="index-table">
            <div class="index-table-hd">{{ $t('banner.title') }}</div>
            <div class="index-table-bd">
              <el-table class="bs-table" :data="tableData" style="width: 100%">
                <el-table-column align="center" prop="pool" label="Pool">
                </el-table-column>
                <el-table-column align="center" prop="apy" label="APY">
                </el-table-column>
                <el-table-column align="center" prop="tvl" label="TVL">
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="btm-foot">
            <el-button type="primary" @click="()=>{
              this.$router.push({path:'/mining',name:'mining',params:{uid:this.uid}});
            }">{{ $t('banner.button') }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="page-index">
      <div class="container">
        <div class="index-section ">
          <div class="index-swiper">
            <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" :key="swiperKey">
              <swiper-slide v-for="(item,index) in lists" :key="index">
                <div class="gut-panel">
                  <div class="gut-card"><img :src="item.photo" alt=""></div>
                  <div class="gut-title">{{ item.title }}</div>
                  <div class="gut-desc">
                    {{ item.intro }}
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-next" slot="button-next">
              <img src="../assets/images/prev.png">
            </div>
            <div class="swiper-button-prev" slot="button-prev">
              <img src="../assets/images/next.png">
            </div>
          </div>
        </div>
        <div class="index-section">
          <div class="bk-cells">
            <div class="col">
              <div class="bk-gpanel">
                <div class="title">{{ $t('Privacy.title') }}</div>
                <div class="desc">
                  {{ $t('Privacy.content') }}
                </div>
                <div class="bk-icos">
                  <div class="ico-box"><img src="../assets/images/1.png" alt=""></div>
                  <div class="ico-box"><img src="../assets/images/2.svg" alt=""></div>
                  <div class="ico-box"><img src="../assets/images/3.svg" alt=""></div>
                  <div class="ico-box"><img src="../assets/images/4.png" alt=""></div>
                  <div class="ico-box"><img src="../assets/images/5.svg" alt=""></div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="bk-bpanel">
                <div class="bk-cell">
                  <div class="hd">{{ $t('Global.title') }}</div>
                </div>
                <div class="bk-cell">
                  <div class="hd">Thousands</div>
                  <div class="bd">of DeFi protocols supported</div>
                </div>
                <div class="bk-cell">
                  <div class="hd">$1 Billion+</div>
                  <div class="bd">Volume traded as of December 2021</div>
                </div>
                <div class="bk-cell">
                  <div class="hd">200,000+</div>
                  <div class="bd">Monthly active users as of December 2021</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="index-section">
          <div class="coop-photo">
            <ul class="coop-list clearfix">
              <li>
                <img src="../assets/images/hz1.png" alt="">
              </li>
              <li>
                <img src="../assets/images/hz2.png" alt="">
              </li>
              <li>
                <img src="../assets/images/hz3.png" alt="">
              </li>
              <li>
                <img src="../assets/images/hz4.png" alt="">
              </li>
              <li>
                <img src="../assets/images/hz1.png" alt="">
              </li>
              <li>
                <img src="../assets/images/hz2.png" alt="">
              </li>
              <li>
                <img src="../assets/images/hz3.png" alt="">
              </li>
              <li>
                <img src="../assets/images/hz4.png" alt="">
              </li>
              <li>
                <img src="../assets/images/hz1.png" alt="">
              </li>
              <li>
                <img src="../assets/images/hz2.png" alt="">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-info">
      {{ $t('foot.cont') }}
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from '@/components/Foot.vue'
export default {
  components: {
    Foot
  },
  mounted() {
    this.$root.$on("i18n",()=>{
      this.update()
    })
    this.$root.$on("uid",(uid)=>{
      this.uid = uid
    })
  },
  data() {
    return {
      uid:undefined,
      swiperKey: 1,
      lists: [],
      tableData: [{
        pool: 'MOVEZ/WBNB',
        apy: '164%',
        tvl: '$9,126.00'
      }, {
        pool: 'MOVEZ/WBNB',
        apy: '164%',
        tvl: '$9,126.00'
      }, {
        pool: 'MOVEZ/WBNB',
        apy: '164%',
        tvl: '$9,126.00'
      }, {
        pool: 'MOVEZ/WBNB',
        apy: '164%',
        tvl: '$9,126.00'
      }, {
        pool: 'MOVEZ/WBNB',
        apy: '164%',
        tvl: '$9,126.00'
      },],
      swiperOptionThumbs: {
        loop: true,
        autoplay: 5000,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: 4,
        spaceBetween: 20,
        breakpoints: {
          640: {
            slidesPerView: 1,
            spaceBetween: 2
          },
          768: { //当屏幕宽度大于等于768
            slidesPerView: 3,
            spaceBetween: 5
          },
          1280: { //当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 20
          }
        }
      },
    };
  },
  methods: {
    update() {
      this.swiperKey = this.swiperKey + 1
      this.lists = [
        {
          photo: require('../assets/images/trusted-1.png'),
          title: this.$t('swiper.trustedTitle1'),
          intro: this.$t('swiper.trustedIntro1')
        }, {
          photo: require('../assets/images/trusted-2.png'),
          title: this.$t('swiper.trustedTitle2'),
          intro: this.$t('swiper.trustedIntro2')
        }, {
          photo: require('../assets/images/trusted-3.png'),
          title: this.$t('swiper.trustedTitle3'),
          intro: this.$t('swiper.trustedIntro3')
        }, {
          photo: require('../assets/images/trusted-4.png'),
          title: this.$t('swiper.trustedTitle4'),
          intro: this.$t('swiper.trustedIntro4')
        },
      ]
    },
  }, created() {
    this.update();
  }
}
</script>
