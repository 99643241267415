<template>
	<van-tabbar class="footer" v-model="activeIndex" :fixed="true" :placeholder="true">
		<van-tabbar-item to="/">
			<div class="font_family icon-hm"></div>
			<div class="item-label">{{$t('nav.nav1')}}</div>
		</van-tabbar-item>
		<van-tabbar-item @click="()=>{
              this.$router.push({path:'/mining',name:'mining',params:{uid:this.uid}});
            }">
			<div class="font_family icon-tz"></div>
			<div class="item-label">{{$t('nav.nav2')}}</div>
		</van-tabbar-item>
		<van-tabbar-item   @click="()=>{
              this.$router.push({path:'/extension',name:'extension',params:{uid:this.uid}});
            }">
			<div class="font_family icon-tg"></div>
			<div class="item-label">{{$t('nav.nav3')}}</div>
		</van-tabbar-item>
		<van-tabbar-item to="/income">
			<div class="font_family icon-wd"></div>
			<div class="item-label">{{$t('nav.nav4')}}</div>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
	export default {
		name: 'Foot',
		props: {
			active: {
				type: Number,
				default: 0
			}
		},mounted() {
      this.$root.$on("uid",(uid)=>{
        this.uid = uid
      })
    },
		computed: {
			activeIndex: {
				get: function () {
					return this.active;
				},
				set: function () {
				}
			}
		},
		data: function() {
			return {
        uid:undefined
      }
		},
	}
</script>
<style>
	.van-tabbar::after {
		display: none;
	}
	.van-tabbar .iconfont{
		color: #c5c5c5;
		font-size: 24px;
	}
	.van-tabbar .item-label{
		color: #c5c5c5;
		margin-top: 4px;
		font-size: 14px;
		padding: 0 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.van-tabbar-item{
		position: relative;
	}
	.van-tabbar .van-tabbar-item--active div{
		color: #192e97;
	}
	.van-tabbar__placeholder {
		height: 80px!important;
	}
</style>