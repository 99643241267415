import axios from 'axios'
import {Message} from 'element-ui'
import {devIp} from '@/api/ipConfig'
import router from '../router'
// create an axios instance

const service = axios.create({
    baseURL: devIp + '', // 测试IP
    timeout: 100000, // request timeout
    headers: {'Content-Type': 'application/json'},
})

// request interceptor
service.interceptors.request.use(config => {
    // Do something before request is sent
    let token = localStorage.getItem('token')
    if (token) {
        config.headers['x-auth-token'] = token
    }
    return config
}, error => {
    // Do something with request error
    // console.log(error); // for debug
    Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
    // response => response,
    response => {
        const res = response.data
        if (res.code === "200") {
            return res
        } else if (res.code === "401") {
            localStorage.removeItem('token')
            router.push({path: '/'})
            Message({
                message: res.msg,
                type: 'error',
                duration: 2 * 1000
            })
        } else {
            Message({
                message: res.msg,
                type: 'error',
                duration: 2 * 1000
            })
            return res
        }
    },
    error => {
        console.log('err' + error)// for debug
        Message({
            message: error.msg,
            type: 'error',
            duration: 3000
        })
        return Promise.reject(error)
    })

export default service
