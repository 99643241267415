import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'vant/lib/index.css';
import Vant from 'vant';
import VueI18n from 'vue-i18n'
// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import clipboard from 'clipboard';
// import style
import 'swiper/swiper-bundle.css'
import "./assets/fonts/iconfont.css";
import "./assets/css/css.css";
import axios from 'axios';
Vue.use(VueI18n)
Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.prototype.$axios = axios;

const i18n = new VueI18n({
    locale: 'English',    // 语言标识
	fallbackLocale: 'English',
    messages: {
      'chinese': require('./common/lang/chinese'),   // 
      'English': require('./common/lang/English'),    // 
      'Japanese': require('./common/lang/Japanese'),   // 
      'Korean': require('./common/lang/Korean')    // 
    }
})
Vue.prototype.clipboard = clipboard;
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
