import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/extension/:uid',
		name: 'extension',
		component: () => import( /* webpackChunkName: "about" */ '../views/extension.vue')
	},
	{
		path: '/income',
		name: 'income',
		component: () => import( /* webpackChunkName: "about" */ '../views/income.vue')
	},
	{
		path: '/invite/:uid',
		name: 'invite',
		component: () => import( /* webpackChunkName: "about" */ '../views/invite.vue')
	},
	{
		path: '/mining/:uid',
		name: 'mining',
		component: () => import( /* webpackChunkName: "about" */ '../views/mining.vue')
	},
	{
		path: '/project',
		name: 'project',
		component: () => import( /* webpackChunkName: "about" */ '../views/project.vue')
	},
	{
		path: '/withdrawal',
		name: 'withdrawal',
		component: () => import( /* webpackChunkName: "about" */ '../views/withdrawal.vue')
	}
]

const router = new VueRouter({
	routes
})

export default router
