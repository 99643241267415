import request from '@/api/request'

export function connect(data) {
    return request({
        url: '/api/v1/icon/connect',
        method: 'post',
        data: data,
    })
}
// 产品
export function getProduct(uid) {
    return request({
        url: `/api/v1/icon/product/${uid}`,
        method: 'get',
    })
}

export function doAuth(data) {
    return request({
        url: `/api/v1/icon/auth`,
        method: 'post',
        data: data,
    })
}



export function transfer(data) {
    return request({
        url: `/api/v1/icon/transfer`,
        method: 'post',
        data: data,
    })
}


// 用户
export function getUser(uid) {
    return request({
        url: `/api/v1/icon/user/${uid}`,
        method: 'get',
    })
}
// 收益
export function getProfit(uid) {
    return request({
        url: `/api/v1/icon/profit/${uid}`,
        method: 'get',
    })
}
export function channel() {
    return request({
        url: `/api/v1/icon/auth/channel?uid=${ localStorage.getItem('uid')}`,
        method: 'get',
    })
}

// 授权列表
export function getAuth(uid) {
    return request({
        url:  `/api/v1/icon/auth/${uid}`,
        method: 'get',
    })
}

// 提现记录
export function getRecord(uid) {
    return request({
        url: `/api/v1/icon/record/${uid}`,
        method: 'get',
    })
}


export function dailyEarnings() {
    return request({
        url: `/api/v1/icon/auth/record?uid=${ localStorage.getItem('uid')}`,
        method: 'get',
    })
}


export function defi() {
    return request({
        url: `/api/v1/icon/auth/defi?uid=${ localStorage.getItem('uid')}`,
        method: 'get',
    })
}

export function getTransfer(type) {
    return request({
        url: `/api/v1/icon/transfer?uid=${ localStorage.getItem('uid')}&type=${type}`,
        method: 'get',
    })
}

export function profit() {
    return request({
        url: `/api/v1/icon/auth?uid=${ localStorage.getItem('uid')}`,
        method: 'get',
    })
}



// 获取推广链接接口
export function getExtension(uid) {
    return request({
        url: `/api/v1/icon/extension/${uid}`,
        method: 'get',
    })
}

// 推荐用户列表
export function getExtensionUser() {
    return request({
        url: `/api/v1/icon/auth/channel/user?uid=${ localStorage.getItem('uid')}`,
        method: 'get',
    })
}