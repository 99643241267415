export const nav = {
	nav1: '首页',
	nav2: '挖矿',
	nav3: '推广',
	nav4: '个人中心',
	connectWallet: '连接钱包',
}

// 轮播图
export const swiper =  {
	trustedTitle1:"安全评估",
	trustedIntro1:"投资风险团队,进行项目评估。",
	trustedTitle2:"平台安全",
	trustedIntro2:"团队核心技术,保护您的钱包。",
	trustedTitle3:"邀请好友",
	trustedIntro3:"推荐朋友,即可享受更多收益。",
	trustedTitle4:"稳定收益",
	trustedIntro4:"加入项目,长期享受稳定收益。",
}


// banner
export const banner = {
	title: 'High Risk Pool',
	button: 'DeFi Mining',
}

// Privacy
export const Privacy = {
	title: 'Privacy and security first',
	content: "With DeFi Hub, you're in control, always. We don't have user accounts and instead work directly with Web3 wallets like MetaMask, Coinbase.This means we don't own your private keys and cannot ever access your funds. We don't cross-associate wallet data and we'll never share your information with third parties.",
}
// Global
export const Global = {
	title: 'Global, free and open to anyone',
}
// foot
export const foot = {
	cont: 'xxxx is legally licensed by the European Malta entertaining!',
}
export const withdrawal = {
	title: 'DeFI 挖矿收益余额',
	zc: '资产',
	syye: '收益余额',
	op: '操作',
	jl: '推荐奖励',
	tx: '提现',
	getjl: '获得奖励',
}
// 推广
export const extension = {
	bantitle: '邀请朋友<br>赚取奖励',
	bandesc: '邀请您的朋友加入，您将获得您的朋友30%DeFi挖矿奖励。',
	exttitle: 'How does the Referral Program work?',
	extcont: "The referral program allows you to create unique referral links that invite your friends to DeFi mining.If anyone clicks the link and DeFi mining,they'll be automatically attributed as your referral. you will get you friends 30% DeFi mining rewards.",
	ext1_tit:"Get your referral link",
	ext1_intro:"Please connect to wallet first and get referral link.",
	ext2_tit:"Invite friends",
	ext2_intro:"Share your referral link to social networks and track the performance of each DeFi mining on the Referral page.",
	ext3_tit:"Earn rewards",
	ext3_intro:"You will get you friends 30% DeFi mining rewards.",
	
}
// 邀请
export const invite = {
	text1: '总推荐人',
	text2: '邀请链接',
	text3: '二维码',
	text4: '您的推荐人',
	text5: '推荐收益',
	product: '产品',
	profitBalance: '收益余额',
	sqSuccess: '申请成功',
	td1: '用户',
	td2: '资产',
	td3: '奖励',
	copy: '复制',
	copyError: '复制失败',
	copySuccess: '复制成功',
	openLink: '没有识别到TronLink,请安装TronLink插件。',
	openLink1: 'TronLink请输入密码后刷新重试。',
}


export const mining = {
	annualizedYieldContent: '年化收益率',
	joni: '加入',
	deFiPeriod: 'DeFi Period',
	balance: 'Wallet Balance',
	yield: 'Daily Yield',
}

// 个人中心
export const ucenter = {
	invtebutton: '邀请朋友',
	table_h: '每日收益详情',
	td1: '资产',
	td2: '收益',
	td3: '时间',
	td4: '总收益',
	td5: '收益余额',
	td6: '推荐奖励',
	td7: '金额',
	td8: '状态',
	leftTab1:"收益详情",
	leftTab2:"我的项目",
	leftTab3:"提现",
	projtab1:"挖矿列表",
	projtab2:"提现记录",
	wtbutton:'提现',
	rewbutton:'获得奖励'
}















