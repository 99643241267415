<template>
  <div id="app">
    <Head/>
    <router-view/>
  </div>
</template>

<script>
import Head from '@/components/Head.vue'

export default {
  components: {
    Head
  },
  data() {
    return {};
  },
  methods: {
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    }

  }, created: function () {
    const ADDRESS_PREFIX_REGEX = /^(41)/;

    // eslint-disable-next-line no-unused-vars
    let value = ["412ed5dd8a98aea00ae32517742ea5289761b2710e","ea00ae32517742ea5289761b2710412ed5dd8a98a"]

    value = value.map(v => v.replace(ADDRESS_PREFIX_REGEX, '0x'));

    console.log("value:{}",value)

    let url = document.location.href;
    localStorage.setItem('url', url)
    // alert(url)
    function getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    }
    localStorage.setItem('channelCode', getQueryString("channelCode"))
    const that = this;
    if (!that.tronWeb) {
      that.tronWeb = window.tronWeb;
      if (that.tronWeb) {
        console.log(that.tronWeb.defaultAddress)
        that.walletAddress = that.tronWeb.defaultAddress.base58;
        localStorage.setItem('address', that.walletAddress)
      }
    }
    window.onbeforeunload = function () {
      //功能函数
      localStorage.clear();
    }
  }
}
</script>
