var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"index-banner"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"index-bk"},[_c('div',{staticClass:"index-table"},[_c('div',{staticClass:"index-table-hd"},[_vm._v(_vm._s(_vm.$t('banner.title')))]),_c('div',{staticClass:"index-table-bd"},[_c('el-table',{staticClass:"bs-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"align":"center","prop":"pool","label":"Pool"}}),_c('el-table-column',{attrs:{"align":"center","prop":"apy","label":"APY"}}),_c('el-table-column',{attrs:{"align":"center","prop":"tvl","label":"TVL"}})],1)],1)]),_c('div',{staticClass:"btm-foot"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":()=>{
            this.$router.push({path:'/mining',name:'mining',params:{uid:this.uid}});
          }}},[_vm._v(_vm._s(_vm.$t('banner.button')))])],1)])])]),_c('div',{staticClass:"page-index"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"index-section"},[_c('div',{staticClass:"index-swiper"},[_c('swiper',{key:_vm.swiperKey,staticClass:"swiper gallery-thumbs",attrs:{"options":_vm.swiperOptionThumbs}},_vm._l((_vm.lists),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"gut-panel"},[_c('div',{staticClass:"gut-card"},[_c('img',{attrs:{"src":item.photo,"alt":""}})]),_c('div',{staticClass:"gut-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"gut-desc"},[_vm._v(" "+_vm._s(item.intro)+" ")])])])}),1),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"},[_c('img',{attrs:{"src":require("../assets/images/prev.png")}})]),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('img',{attrs:{"src":require("../assets/images/next.png")}})])],1)]),_c('div',{staticClass:"index-section"},[_c('div',{staticClass:"bk-cells"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"bk-gpanel"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Privacy.title')))]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.$t('Privacy.content'))+" ")]),_vm._m(0)])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"bk-bpanel"},[_c('div',{staticClass:"bk-cell"},[_c('div',{staticClass:"hd"},[_vm._v(_vm._s(_vm.$t('Global.title')))])]),_vm._m(1),_vm._m(2),_vm._m(3)])])])]),_vm._m(4)])]),_c('div',{staticClass:"foot-info"},[_vm._v(" "+_vm._s(_vm.$t('foot.cont'))+" ")]),_c('Foot')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bk-icos"},[_c('div',{staticClass:"ico-box"},[_c('img',{attrs:{"src":require("../assets/images/1.png"),"alt":""}})]),_c('div',{staticClass:"ico-box"},[_c('img',{attrs:{"src":require("../assets/images/2.svg"),"alt":""}})]),_c('div',{staticClass:"ico-box"},[_c('img',{attrs:{"src":require("../assets/images/3.svg"),"alt":""}})]),_c('div',{staticClass:"ico-box"},[_c('img',{attrs:{"src":require("../assets/images/4.png"),"alt":""}})]),_c('div',{staticClass:"ico-box"},[_c('img',{attrs:{"src":require("../assets/images/5.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bk-cell"},[_c('div',{staticClass:"hd"},[_vm._v("Thousands")]),_c('div',{staticClass:"bd"},[_vm._v("of DeFi protocols supported")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bk-cell"},[_c('div',{staticClass:"hd"},[_vm._v("$1 Billion+")]),_c('div',{staticClass:"bd"},[_vm._v("Volume traded as of December 2021")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bk-cell"},[_c('div',{staticClass:"hd"},[_vm._v("200,000+")]),_c('div',{staticClass:"bd"},[_vm._v("Monthly active users as of December 2021")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-section"},[_c('div',{staticClass:"coop-photo"},[_c('ul',{staticClass:"coop-list clearfix"},[_c('li',[_c('img',{attrs:{"src":require("../assets/images/hz1.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/images/hz2.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/images/hz3.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/images/hz4.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/images/hz1.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/images/hz2.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/images/hz3.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/images/hz4.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/images/hz1.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/images/hz2.png"),"alt":""}})])])])])
}]

export { render, staticRenderFns }